.ant-dropdown {
  ul {
    background-color: #dcba78 !important;

    li:hover {
      background-color: #2d4600 !important;
      color: white !important;
    }
  }
}
.select-language {
  .ant-select-selector {
    border-radius: 0px !important;
  }
}
.upload_img_admin {
  .ant-upload-list {
    .ant-upload-list-item {
      border: none !important;
      padding: 0px !important;
      width: 120px !important;
      height: 120px !important;
      margin-top: 0px !important;
      .ant-upload-list-item-name {
        display: none;
      }
      .ant-upload-list-item-thumbnail {
        width: 120px !important;
        height: 120px !important;
      }
    }
  }
  .ant-upload-list-picture {
    position: absolute !important;
    top: 0% !important;
  }
}
.slc_role {
  .ant-select-selector {
    height: 40px !important;
    border: 1px solid black !important;
    display: flex;
    align-items: center;
  }
}
