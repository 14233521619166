.colap_banner {
  .ant-collapse-item {
    border-radius: 0px !important;
    border-bottom: none;
    .ant-collapse-header {
      background-color: #dcba78;
      border-radius: 0px !important;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
    }
    .ant-collapse-content {
      border-radius: 0px !important;
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #dbb312;
      }
      .ant-collapse-content-active {
        border-top: none;
        .ant-collapse-content-box {
          padding: 0px;
        }
      }
    }
    &.ant-collapse-item-active:focus {
      .ant-collapse-header {
        background-color: #dbb312;
      }
      .ant-collapse-content-active {
        border-top: none;
        .ant-collapse-content-box {
          padding: 0px;
        }
      }
    }
  }
}
.css-dev-only-do-not-override-1me4733.ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0px 0px;
}
.css-dev-only-do-not-override-1me4733.ant-collapse .ant-collapse-content {
  border-top: none;
}
.swiper_home {
  .swiper-pagination-bullets {
    margin-top: 15px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-pagination {
    position: initial;
    .swiper-pagination-bullet {
      background: rgba(0, 0, 0, 0.8);
      height: 8px;
      width: 8px;
      margin-right: 8px;
      &-active {
        background: #595959;
      }
    }
  }
}
.switch-lang {
  .ant-switch {
    background: rgba(0, 0, 0, 0.2);
    width: 49px;
    height: 28px;
    border-radius: 8;

    .ant-switch-handle {
      border-radius: 8;
      top: 5px;
      left: 8px;
      right: 8px;
      width: 17px;
      height: 18px;

      &.ant-switch-handle::before {
        background-color: black;
      }
    }

    &.ant-switch-checked:focus {
      box-shadow: none;
    }

    &.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 20px - 2px);
      }

      .ant-switch-handle::before {
        background-color: #fff;
      }
    }
  }

  .ant-switch:focus {
    outline: none;
    box-shadow: none;
  }

  .ant-switch-checked {
    background: rgba(0, 0, 0, 0.1);
  }
}
.swiper_slide:hover {
  .btn_change {
    opacity: 100;
  }
}
.modal_edit_img {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
}
.slc_ranking {
  .ant-select-selector {
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    border-radius: 10px;
    height: 36px !important;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      color: #0038ff;
    }
  }
  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}
.cropContainer {
  .reactEasyCrop_CropArea {
    width: 100% !important;
    // height: 100% !important;
  }
  .reactEasyCrop_Image {
    width: 100%;
  }
}
.modal_open_img {
  width: 677px !important;
  .ant-modal-content {
    height: 800px;
  }
  // .ant-modal-footer,
  .ant-modal-close {
    display: none;
  }
}
.crop_img_product {
  .reactEasyCrop_Container {
    margin-left: 103px !important;
    margin-top: 35px !important;
    height: 560px !important;
    width: 470px !important;
    // .reactEasyCrop_Image {
    //   margin-top: 41px !important;

    //   height: 560px !important;
    //   width: 470px !important;
    // }
  }
  .reactEasyCrop_CropArea {
    height: 560px !important;
    width: 470px !important;
  }
}
.controls {
  position: absolute !important;
  top: 111% !important;
  right: 41% !important;
  width: 150px !important;
  .MuiSlider-colorPrimary {
    padding: 0px !important;
    height: 0px !important;
    color: black !important;
    .MuiSlider-rail {
      border: 1px solid black !important;
      border-top-right-radius: 2.5px !important;
      border-bottom-right-radius: 2.5px !important;
      height: 5px !important;
      background-color: white;
    }
    .MuiSlider-thumbColorPrimary {
      height: 9px !important;
      width: 9px !important;
      margin-top: -2.5px !important;
    }
    .MuiSlider-track {
      height: 5px !important;
      border-top-left-radius: 2.5px !important;
      border-bottom-left-radius: 2.5px !important;
      border-radius: 2.5px;
    }
  }
}
.controls_1 {
  position: absolute !important;
  top: 83% !important;
  right: 34% !important;
  width: 150px !important;
  .MuiSlider-colorPrimary {
    padding: 0px !important;
    height: 0px !important;
    color: black !important;
    .MuiSlider-rail {
      border: 1px solid black !important;
      border-top-right-radius: 2.5px !important;
      border-bottom-right-radius: 2.5px !important;
      height: 5px !important;
      background-color: white;
    }
    .MuiSlider-thumbColorPrimary {
      height: 9px !important;
      width: 9px !important;
      margin-top: -2.5px !important;
    }
    .MuiSlider-track {
      height: 5px !important;
      border-top-left-radius: 2.5px !important;
      border-bottom-left-radius: 2.5px !important;
      border-radius: 2.5px;
    }
  }
}
