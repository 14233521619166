.herder-client {
  .btn-download {
    span {
      padding-left: 10px;
    }
  }
}

.table-client {
  .ant-table-container {
    border-start-start-radius: 0px;
    border-start-end-radius: 0px;

    .ant-table-thead {
      .ant-table-row-expand-icon-cell {
        background: white;
        border-top: 1px solid !important;
        border-bottom: 1px solid !important;
      }
    }

    .ant-table-tbody {
      .ant-table-expanded-row {
        .ant-table-cell {
          background: white;

          .ant-table-expanded-row-fixed {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }

    .ant-table-cell::before {
      width: 0px !important;
    }

    .ckb_table {
      .ant-checkbox-inner {
        // background: #ffffff;
        border: 0.1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 1px rgb(0 0 0 / 25%);
        border-radius: 2px;
        height: 20px;
        width: 20px;
      }
    }

    th {
      border-start-start-radius: 0px !important;

      &.ant-table-cell-fix-left {
        background: white;
        border-left: 0.5px solid;
      }
    }

    th {
      border-start-end-radius: 0px !important;

      &.ant-table-cell-fix-right {
        background: white;
        border-right: 0.5px solid;
      }
    }

    th {
      &.ant-table-cell {
        background: white;
        border-top: 0.5px solid;
        border-bottom: 0.5px solid;
        height: 50px;
        padding: 0px !important;
        // text-align: center;
      }
    }

    td {
      &.ant-table-cell {
        padding: 0px !important;
        height: 60px;
        // text-align: center;
      }

      &.ant-table-cell-fix-left {
        border-left: 0.1px solid rgba(0, 0, 0, 0.3);
      }

      &.ant-table-cell-fix-right {
        border-right: 0.1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.modal-quick-view {
  .ant-modal-content {
    width: 700px;
    background-color: #fbf8f1;
    padding: 0;
    border-radius: 0;
    border: 1px solid #dbb312;

    .ant-modal-body {
      padding-right: 20px;
      padding-top: 20px;
      padding: 0;
    }
    .ant-modal-close {
      margin-top: -17px;
      margin-right: -16px;
      .ant-modal-close-x {
        svg {
          fill: #dbb312;
        }
      }
    }
  }
}

.ant-modal-mask {
  background-color: none;
}
