.check_tag_blog {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    height: 27px;
    margin-top: 2px;
    margin-bottom: 2px;
    .ant-checkbox {
      display: none;
    }
  }
  .ant-checkbox-wrapper-checked {
    background: rgba(62, 97, 0, 0.3);
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 3px;
    height: 27px;
    display: flex;
    align-items: center;
    .ant-checkbox-checked {
      display: none;
    }
  }
}
.inp_upload {
  ::placeholder {
    opacity: 100 !important;
  }
}
.pop_blog_add {
  .ant-checkbox-wrapper-checked {
    margin-bottom: 2px;
  }
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox-wrapper:hover {
    background: rgba(62, 97, 0, 0.3);
  }
}
