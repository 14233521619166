@font-face {
  font-family: 'MPlus1pRegular';
  src: url('../../fonts/MPLUS1p-Regular.ttf');
}
.slc_status {
  .ant-select-selector {
    width: 243px !important;
  }
}
.slc_merchandise {
  .ant-select-selector {
    height: 30px !important;
    border: 0.5px solid !important;
    // display: flex;
    // align-items: center;
    .ant-select-selection-item {
      font-size: 14px;
      font-family: 'MPlus1pRegular';
    }
  }

  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}

.ctn_btn_merchandise {
  @include scrollbar;
}

.table_merchandise {
  .ant-table-container {
    border-start-start-radius: 0px;
    border-start-end-radius: 0px;

    .ant-table-thead {
      .ant-table-row-expand-icon-cell {
        background: white;
        border-top: 1px solid !important;
        border-bottom: 1px solid !important;
      }
    }

    .ant-table-tbody {
      .ant-table-expanded-row {
        .ant-table-cell {
          background: white;

          .ant-table-expanded-row-fixed {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }

    .ant-table-cell::before {
      width: 0px !important;
    }

    .ckb_table {
      .ant-checkbox-inner {
        // background: #ffffff;
        border: 0.1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 1px rgb(0 0 0 / 25%);
        border-radius: 2px;
        height: 20px;
        width: 20px;
      }
    }

    th {
      border-start-start-radius: 0px !important;

      &.ant-table-cell-fix-left {
        background: white;
        border-left: 0.5px solid;
      }
    }

    th {
      border-start-end-radius: 0px !important;

      &.ant-table-cell-fix-right {
        background: white;
        border-right: 0.5px solid;
      }
    }

    th {
      &.ant-table-cell {
        background: white;
        border-top: 0.5px solid;
        border-bottom: 0.5px solid;
        height: 50px;
        padding: 0px !important;
        // text-align: center;
      }
    }

    td {
      &.ant-table-cell {
        border-bottom: 0.1px solid rgba(0, 0, 0, 0.3) !important;
        padding: 0px !important;
        height: 60px;
        // text-align: center;
      }

      &.ant-table-cell-fix-left {
        border-left: 0.1px solid rgba(0, 0, 0, 0.3);
      }

      &.ant-table-cell-fix-right {
        border-right: 0.1px solid rgba(0, 0, 0, 0.3);
      }
    }

    tbody {
      tr[data-row-key='1'],
      tr[data-row-key='3'],
      tr[data-row-key='5'],
      tr[data-row-key='7'],
      tr[data-row-key='9'] {
        background: #fbf8f1;

        .ant-table-cell-fix-left {
          background: #fbf8f1;
        }

        .ant-table-cell-fix-right {
          background: #fbf8f1;
        }
      }
    }
  }
}

.pagi_merchandise {
  li {
    font-size: 10px;

    &.ant-pagination-item-active {
      border: none;
      background: rgba(176, 194, 132, 0.05);
      text-decoration: underline;

      a {
        color: black;
      }
    }
  }
}

.form_add_merchandise {
  .ql-toolbar {
    display: flex;
    height: 44px;
    align-items: center;

    .ql-formats:first-child {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }

  .ql-container {
    height: 173px;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 12px;

    .ql-editor {
      border: 1px solid rgba(0, 0, 0, 0.3);

      &::-webkit-scrollbar {
        border-radius: 16px;
        background-color: #fff;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        // border: 10px solid #fff;
        margin-right: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        // border: 4px solid #fff;

        &:hover {
          background: #bbb;
        }
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
}

.upload_img {
  .ant-upload-select {
    .ant-upload {
      width: 132px;
    }
  }
  .ant-upload-list-item-container {
    height: 132px !important;
    width: 132px !important;
    margin-block: 0px !important;
    margin-inline: 0px !important;

    .ant-tooltip {
      display: none;
    }

    .ant-upload-list-item {
      height: 132px !important;
      width: 132px !important;
      padding: 0px !important;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
      border-radius: 0px !important;
      margin-right: 5px !important;

      &::before {
        height: 132px !important;
        width: 132px !important;
      }

      .ant-upload-list-item-actions {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          display: none;
        }
      }
    }
  }

  .ant-upload-select {
    height: 132px !important;
    width: 132px !important;
    margin-inline-end: 0px !important;
    margin-bottom: 0px !important;
    border: 0.1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px !important;
    background-color: white !important;
  }
}

.slc_tax {
  .ant-select-selector {
    height: 27px !important;
    border-color: rgba(0, 0, 0, 0.4) !important;
  }

  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}
.review_tbl {
  &::-webkit-scrollbar {
    border-radius: 16px;
    background-color: #fff;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    // border: 20px solid blue;
    // margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    // border: 10px solid red;

    &:hover {
      background: #bbb;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.slc_search {
  .ant-select-selection-overflow {
    display: none;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-clear {
    display: none;
  }
}
.ant-select-item-option-state {
  display: none;
}
// .ant-select-item-option-selected {
//   margin-top: 5px;
//   background-color: rgba(62, 97, 0, 0.3) !important;
// }

.slc_right {
  .ant-select-selector {
    height: 29px !important;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
  }

  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}

.svg_start:hover {
  // background-color: red;
  svg {
    path {
      fill: #dbb312;
    }
  }
}

.slc_cate {
  .ant-select-selector {
    border-radius: 0px !important;
    height: 39px !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
  }

  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}

.text_area_add {
  .ant-input {
    border-radius: 0px !important;
  }
}

.tbl_cate {
  th {
    &.ant-table-cell:first-child {
      border-left: 1px solid;
    }

    &.ant-table-cell:last-child {
      border-right: 1px solid;
    }
  }

  td {
    &.ant-table-cell:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.3);
    }

    &.ant-table-cell:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}

.slc_catego {
  .ant-select-selector {
    height: 39px !important;
    border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.6) !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-arrow {
    svg {
      fill: black;
    }
  }
}

.ckb_add {
  .ant-checkbox {
    top: 0px !important;
  }

  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
}

.date_picker {
  .ant-picker-input {
    input::placeholder {
      // display: none;
      color: white;
    }
  }

  .ant-picker-suffix {
    display: none;
  }
}
.ant-rate {
  height: 20px;
  display: flex;
  .anticon-star {
    display: flex;
  }
}
.ckb_gr {
  .ant-checkbox-group-item {
    margin-inline-start: 0px !important;
  }
}
.modal_delete {
  .ant-modal-content {
    height: 270px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    .ant-modal-body {
      height: 100%;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  }
}
.ant-select-dropdown {
  background: rgb(220, 186, 120);
  width: 156px;
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #3e6100;
    color: white;
  }
  .ant-select-item-option-active:hover {
    background: #3e6100;
    color: white;
  }
  .ant-select-item-option {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
.ckb_brand {
  .ant-checkbox-wrapper-checked {
    background: #c5d0b3;
  }
  .ant-checkbox-checked:after {
    border: none;
  }
  .ant-checkbox-wrapper-checked:hover {
    .ant-checkbox-inner {
      background-color: white;
    }
    .ant-checkbox-checked:after {
      border: none;
      .ant-checkbox-inner {
        border-radius: 0px;
        height: 15px;
        width: 15px;
        background-color: white !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
      }
      .ant-checkbox-inner::after {
        border: 1px solid black;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  span {
    margin-right: 12px;
    padding: 0px;
  }
  .ant-checkbox-inner {
    border-radius: 0px;
    height: 15px;
    width: 15px;
    background-color: white !important;
    border-color: rgba(0, 0, 0, 0.3) !important;
  }
  .ant-checkbox-inner::after {
    border: 1px solid black;
    border-top: 0;
    border-left: 0;
  }
}
.ant-popover-arrow::before {
  display: none;
}
.ckb_tag_product {
  .ant-checkbox-checked:after {
    border: none;
  }
  .ant-checkbox-wrapper-checked:hover {
    .ant-checkbox-inner {
      background-color: white;
    }
    .ant-checkbox-checked:after {
      border: none;
      .ant-checkbox-inner {
        border-radius: 0px;
        height: 15px;
        width: 15px;
        background-color: white !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
      }
      .ant-checkbox-inner::after {
        border: 1px solid black;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .ant-checkbox-wrapper {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    span {
      margin-right: 12px;
      padding: 0px;
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 0px;
        height: 15px;
        width: 15px;
        background-color: white !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
      }
      .ant-checkbox-inner::after {
        border: 1px solid black;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .ant-checkbox-wrapper-checked {
    background: #c5d0b3;
    border-radius: 2px;
  }
}
.slc_date {
  .ant-picker-input {
    input {
      &::placeholder {
        color: white;
      }
    }
  }
}
.none_date {
  input[title] {
    color: white;
  }
}
.table_merchandise {
  .ant-table-tbody > tr.ant-table-row-selected > th,
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
    background: white !important;
  }
  .ant-table-selection-column {
    background: white;
  }
  &.ckb_brand {
    .ant-checkbox-wrapper-checked {
      background: none;
    }
    span {
      margin-right: 0px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell-fix-left {
      .ant-table-selection {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .ant-checkbox-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }
    tr[data-row-key='0'],
    tr[data-row-key='2'],
    tr[data-row-key='4'],
    tr[data-row-key='6'],
    tr[data-row-key='8'] {
      &.ant-table-row-selected {
        td {
          background: white !important;
        }
      }
    }
    tr[data-row-key='1'],
    tr[data-row-key='3'],
    tr[data-row-key='5'],
    tr[data-row-key='7'],
    tr[data-row-key='9'] {
      &.ant-table-row-selected {
        td {
          background: #fbf8f1 !important;
        }
      }
    }
  }
  tbody {
    tr[data-row-key='1'],
    tr[data-row-key='3'],
    tr[data-row-key='5'],
    tr[data-row-key='7'],
    tr[data-row-key='9'] {
      background: #fbf8f1;

      .ant-table-cell-fix-left {
        background: #fbf8f1;
      }

      .ant-table-cell-fix-right {
        background: #fbf8f1;
      }
    }
  }
}
.ant-upload-select .ant-upload .img_none {
  display: none;
}
.svg_close_img {
  svg {
    path {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}
.upload_img {
  .ant-upload-list {
    display: flex;
  }
}
.time_sale {
  .ant-picker-input {
    input::placeholder {
      color: white;
    }
    .ant-picker-suffix {
      .anticon {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}
.ant-picker-footer {
  .ant-picker-ranges {
    .ant-picker-ok {
      .ant-btn-primary {
        background-color: #1677ff;
      }
    }
  }
}
.inp_reply {
  .ant-input-suffix {
    height: 100%;
    display: flex;
    align-items: end;
  }
}
