// .inp_pass {
//   &.ant-input-affix-wrapper-focused {
//     border: none;

//     .ant-input {
//       outline: none;
//       height: 45px;
//     }
//   }
//   .ant-input {
//     width: 100%;
//   }
//   .ant-input-suffix {
//     display: flex;
//   }
// }
.ckb_pass {
  .ant-checkbox {
    display: flex;
    margin-right: 10px;
  }
}
// .ant-form-item-has-error {
//   .ant-input-affix-wrapper-focused {
//     #basic_password {
//       // box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
//       border-top: 1px soliD #ff4d4f;
//       border-bottom: 1px soliD #ff4d4f;
//     }
//   }
//   .inp_email,
//   .inp_pass {
//     border: 1px soliD #ff4d4f;
//   }
//   .inp_email:focus,
//   .inp_pass:focus {
//     border: 1px soliD #ff4d4f;
//     box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
//   }
// }
