$primary: #666687;
$gray: #555;
$gray2: #999;
$gray3: #f5f6f8;
$gray4: #f5f5f5;
$red: #ff7370;
$blue: #0076ff;

@mixin flex($center: false, $middle: false) {
  display: flex;

  @if ($center) {
    align-items: center;
  }

  @if ($middle == m) {
    justify-content: center;
  }

  @if ($middle == s) {
    justify-content: space-between;
  }
}

@mixin xxxl {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    border-radius: 16px;
    background-color: #fff;
    width: 1px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 16px;
    // border: 4px solid #fff;

    &:hover {
      background: #bbb;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin shadow {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}
