@font-face {
  font-family: 'MPlus1pExtraBold';
  src: url('../../fonts/MPLUS1p-ExtraBold.ttf');
}
.icon_belll {
  svg {
    width: 45px;
    height: 45px;
  }
}
.right-side,
.content-left {
  @include scrollbar;
}
.ant-popconfirm-message {
  display: none !important;
}
.ant-popconfirm-buttons {
  display: none !important;
}
.ant-popconfirm-description {
  margin-inline-start: 0px !important;
  margin-bottom: 0px !important;
}
.ant-popover-inner {
  padding: 0px !important;
}
.pop_confirm_btn:focus {
  span {
    box-shadow: none;
  }
}
.pop_confirm_btn:hover {
  span {
    color: black;
  }
}
.menu_sidebar {
  .ant-menu-submenu {
    &.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        background: rgba(0, 0, 0, 0.25) !important;
      }
      .ant-menu-item-active {
        background: #2d4600 !important;
        box-shadow: none !important;
      }
      .ant-menu-item-selected {
        background: #2d4600 !important;
        span {
          font-family: 'MPlus1pExtraBold';
        }
      }
      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background: #dbb312 !important;
          box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
        }
        .ant-menu-item-active {
          background: #2d4600 !important;
          box-shadow: none !important;
        }
        .ant-menu-item-selected {
          background: #2d4600 !important;
          box-shadow: none !important;
          span {
            font-family: 'MPlus1pExtraBold';
          }
        }
      }
      .ant-menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ant-menu-item-only-child:first-child {
          border-top: none !important;
        }
        .ant-menu-item-only-child {
          border-top: 0.3px solid black;
          background: #2d4600;
          width: 80%;
          display: flex;
          justify-content: center;
          padding-left: 5% !important;
          .ant-menu-title-content {
            padding-left: 0px !important;
          }
        }
      }
    }
    .ant-menu-submenu-title:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    .ant-menu-submenu-title {
      border-top: 0.5px solid black;
      padding-inline-end: 0px !important;
      padding-inline: 0px !important;
      height: 50px;
      background: rgba(0, 0, 0, 0.25);
      padding-left: 0px !important;
      margin-inline: 0px !important;
      margin-block: 0px !important;
      width: 100%;
      border-radius: 0px !important;
      .ant-menu-title-content {
        padding-left: 15%;
      }
    }
    .ant-menu-submenu-arrow {
      // display: none;
      color: white;
    }
  }
  .ant-menu-item {
    border-top: 0.5px solid black;
    padding-inline-end: 0px !important;
    padding-inline: 0px !important;
    height: 50px;
    background: rgba(0, 0, 0, 0.25);
    padding-left: 0px !important;
    margin-inline: 0px !important;
    margin-block: 0px !important;
    width: 100%;
    border-radius: 0px !important;
    .ant-menu-title-content {
      padding-left: 15%;
    }
    &.ant-menu-item-active {
      background: rgba(0, 0, 0, 0.5) !important;
    }
    &.ant-menu-item-selected {
      background: #dbb312 !important;
      box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
    }
  }
}
.ant-form-item-required {
  color: white !important;
}
