.ctn_overflow_shopmana {
  &::-webkit-scrollbar {
    border-radius: 16px;
    background-color: #fff;
    width: 5px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 16px;
    // border: 4px solid #fff;

    &:hover {
      background: #bbb;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
