// @font-face {
//   font-family: "SF Display";
//   font-weight: 400;
//   src: url("../../fonts/SF-Pro-Display-Regular.otf");
// }

// @font-face {
//   font-family: "SF Display";
//   font-weight: 500;
//   src: url("../../fonts/SF-Pro-Display-Medium.otf");
// }

// @font-face {
//   font-family: "SF Display";
//   font-weight: bold;
//   src: url("../../fonts/SF-Pro-Display-Semibold.otf");
// }

// @font-face {
//   font-family: "NotoSans";
//   font-weight: 400;
//   src: url("../../fonts/NotoSansJP-Light.otf");
// }

// @font-face {
//   font-family: "NotoSans";
//   font-weight: bold;
//   src: url("../../fonts/NotoSansJP-Bold.otf");
// }

// @font-face {
//   font-family: "NotoSans";
//   font-weight: 400;
//   src: url("../../fonts/NotoSansJP-Regular.otf");
// }

// @font-face {
//   font-family: "NotoSans";
//   font-weight: 500;
//   src: url("../../fonts/NotoSansJP-Medium.otf");
// }

// @font-face {
//   font-family: "NotoSans";
//   font-weight: bold;
//   src: url("../../fonts/NotoSansJP-Bold.otf");
// }
@font-face {
  font-family: 'MPlus1pThin';
  src: url('../../fonts/MPLUS1p-Thin.ttf');
}

@font-face {
  font-family: 'MPlus1pLight';
  src: url('../../fonts/MPLUS1p-Light.ttf');
}

@font-face {
  font-family: 'MPlus1pRegular';
  src: url('../../fonts/MPLUS1p-Regular.ttf');
}

@font-face {
  font-family: 'MPlus1pMedium';
  src: url('../../fonts/MPLUS1p-Medium.ttf');
}

@font-face {
  font-family: 'MPlus1pBold';
  src: url('../../fonts/MPLUS1p-Bold.ttf');
}

@font-face {
  font-family: 'MPlus1pExtraBold';
  src: url('../../fonts/MPLUS1p-ExtraBold.ttf');
}

@font-face {
  font-family: 'MPlus1pBlack';
  src: url('../../fonts/MPLUS1p-Black.ttf');
}

.mplus1pthin {
  font-family: 'MPlus1pThin';
  // font-family: 'M PLUS 1p', sans-serif;
}

.mplus1plight {
  font-family: 'MPlus1pLight';
  // font-family: 'M PLUS 1p', sans-serif;
}

.mplus1pnormal {
  font-family: 'MPlus1pRegular';
  // font-family: 'M PLUS 1p', sans-serif;
}

.mplus1pmedium {
  font-family: 'MPlus1pMedium';
}

.mplus1pbold {
  font-family: 'MPlus1pBold' !important;
  // font-family: 'M PLUS 1p', sans-serif;
}

.mplus1pextrabold {
  font-family: 'MPlus1pExtraBold';
  // font-family: 'M PLUS 1p', sans-serif;
}

.mplus1pblack {
  font-family: 'MPlus1pBlack';
  // font-family: 'M PLUS 1p', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'MPlus1pRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  margin: 0;
  overflow-x: hidden;
  font-weight: 400;
  font-size: 14px;
  background: $gray3;

  @include scrollbar;
}

a {
  outline: none;
  text-decoration: none;
  font-weight: 400;
  color: inherit;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  border: none;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: inherit;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 2.1em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.15em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.85em;
}

button,
p,
label {
  font-family: 'MPlus1pRegular' !important;
  margin: 0;
}

button {
  transition: 0.3s !important;
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 14px;

  &.primary {
    background: $primary;
  }
}

button:focus {
  outline: none;
}

.swal2-popup.swal2-toast {
  min-width: 300px;
}

.ant-modal-footer button+button {
  min-width: 65px;
}

.title-page {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: 600;
    line-height: 1;
  }

  @include sm {
    margin-bottom: 15px;

    h2 {
      font-size: 16px;
    }
  }
}

.back-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: $gray;
  border-radius: 50%;
  margin-right: 10px;

  &:hover {
    color: white;
    background: $primary;
  }
}

.input-login {
  position: relative;
  margin-top: 10px;

  input {
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    padding: 0 15px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    padding-top: 2px;

    &:focus {
      outline: $primary auto 1px;
    }

    &:disabled {
      background: #e1e1e1;
    }

    &::placeholder {
      color: $gray2;
    }
  }

  h5 {
    position: absolute;
    padding: 0 10px;
    background: white;
    left: 15px;
    top: -10px;
    color: $gray;
  }

  &.error {
    input {
      border-color: $red;
    }
  }
}

// .select {
//   position: relative;

//   select {
//     -moz-appearance: none;
//     -webkit-appearance: none;
//     appearance: none;
//   }

//   &:before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     right: 15px;
//     border-right: 10px solid transparent;
//     border-top: 10px solid #999;
//     transform: translateY(-100%) rotate(225deg);
//   }
// }

.mt {
  &-30 {
    margin-top: 30px !important;
  }

  &-25 {
    margin-top: 25px !important;
  }

  &-20 {
    margin-top: 20px !important;
  }

  &-15 {
    margin-top: 15px !important;
  }

  &-10 {
    margin-top: 10px !important;
  }

  &-5 {
    margin-top: 5px !important;
  }

  &-0 {
    margin-top: 0 !important;
  }
}

.mb {
  &-30 {
    margin-bottom: 30px !important;
  }

  &-25 {
    margin-bottom: 25px !important;
  }

  &-20 {
    margin-bottom: 20px !important;
  }

  &-15 {
    margin-bottom: 15px !important;
  }

  &-10 {
    margin-bottom: 10px !important;
  }

  &-5 {
    margin-bottom: 5px !important;
  }

  &-0 {
    margin-bottom: 0 !important;
  }
}

.mr {
  &-30 {
    margin-right: 30px !important;
  }

  &-25 {
    margin-right: 25px !important;
  }

  &-20 {
    margin-right: 20px !important;
  }

  &-15 {
    margin-right: 15px !important;
  }

  &-10 {
    margin-right: 10px !important;
  }

  &-5 {
    margin-right: 5px !important;
  }

  &-0 {
    margin-right: 0 !important;
  }
}

.ml {
  &-30 {
    margin-left: 30px !important;
  }

  &-25 {
    margin-left: 25px !important;
  }

  &-20 {
    margin-left: 20px !important;
  }

  &-15 {
    margin-left: 15px !important;
  }

  &-10 {
    margin-left: 10px !important;
  }

  &-5 {
    margin-left: 5px !important;
  }

  &-0 {
    margin-left: 0 !important;
  }
}

.p {
  &-30 {
    padding: 30px !important;
  }

  &-25 {
    padding: 25px !important;
  }

  &-20 {
    padding: 20px !important;
  }

  &-15 {
    padding: 15px !important;
  }

  &-10 {
    padding: 10px !important;
  }

  &-5 {
    padding: 5px !important;
  }

  &-0 {
    padding: 0 !important;
  }
}

.pt {
  &-30 {
    padding-top: 30px !important;
  }

  &-25 {
    padding-top: 25px !important;
  }

  &-20 {
    padding-top: 20px !important;
  }

  &-15 {
    padding-top: 15px !important;
  }

  &-10 {
    padding-top: 10px !important;
  }

  &-5 {
    padding-top: 5px !important;
  }

  &-0 {
    padding-top: 0 !important;
  }
}

.pb {
  &-30 {
    padding-bottom: 30px !important;
  }

  &-25 {
    padding-bottom: 25px !important;
  }

  &-20 {
    padding-bottom: 20px !important;
  }

  &-15 {
    padding-bottom: 15px !important;
  }

  &-10 {
    padding-bottom: 10px !important;
  }

  &-5 {
    padding-bottom: 5px !important;
  }

  &-0 {
    padding-bottom: 0 !important;
  }
}

.pr {
  &-30 {
    padding-right: 30px !important;
  }

  &-25 {
    padding-right: 25px !important;
  }

  &-20 {
    padding-right: 20px !important;
  }

  &-15 {
    padding-right: 15px !important;
  }

  &-10 {
    padding-right: 10px !important;
  }

  &-5 {
    padding-right: 5px !important;
  }

  &-0 {
    padding-right: 0 !important;
  }
}

.pl {
  &-30 {
    padding-left: 30px !important;
  }

  &-25 {
    padding-left: 25px !important;
  }

  &-20 {
    padding-left: 20px !important;
  }

  &-15 {
    padding-left: 15px !important;
  }

  &-10 {
    padding-left: 10px !important;
  }

  &-5 {
    padding-left: 5px !important;
  }

  &-0 {
    padding-left: 0 !important;
  }
}

.d {
  &-flex {
    display: flex;
  }

  &-block {
    display: block;
  }

  &-none {
    display: none;
  }
}

.center {
  align-items: center;
}

.middle {
  justify-content: center;
}

.weight {
  font-weight: 600;
}

.gray {
  color: gray;
}

.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-20 {
  font-size: 20px;
}

.size-24 {
  font-size: 24px;
}

.size-30 {
  font-size: 30px;
}

.size-40 {
  font-size: 40px;
}

.delete-action {
  background: $red;
  display: flex;
  align-items: center;
  color: white;
  height: 30px;
  padding: 0 12px;
  border-radius: 3px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgba($red, 0.8);
  }
}

.edit-action {
  background: $blue;
  display: flex;
  align-items: center;
  color: white;
  height: 30px;
  padding: 0 12px;
  border-radius: 3px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgba($blue, 0.8);
  }
}

.ant-picker-suffix {
  overflow: initial;
}

.ant-btn-icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include xs {
  .hide-xs {
    display: none;
  }
}

@include sm {
  .hide-sm {
    display: none;
  }
}

@include md {
  .hide-md {
    display: none;
  }
}

@include lg {
  .hide-lg {
    display: none;
  }
}

@include xl {
  .hide-xl {
    display: none;
  }
}

@include xxl {
  .hide-xxl {
    display: none;
  }
}

@include xxxl {
  .hide-xxxl {
    display: none;
  }
}